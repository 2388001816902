(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/math/assets/javascripts/math.js') >= 0) return;  svs.modules.push('/components/utils/math/assets/javascripts/math.js');
var svs = svs || {};

(function(svs) {
  'use strict';

  svs.utils = svs.utils || {};
  svs.utils.math = svs.utils.math || {};

  svs.utils.math.Factorial = function factorial(n) {
    var j = 1;
    for (var i = 1; i <= n; i++) {
      j = j * i;
    }
    return j;
  };

  svs.utils.math.IsInt = function isInt(n) {
    return /^\d+$/.test(n);
  };

  if (typeof exports === 'object') {
    module.exports = svs.utils.math;
  }
})(svs);


 })(window);