(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/math/assets/javascripts/helpers/handlebarhelper.js') >= 0) return;  svs.modules.push('/components/utils/math/assets/javascripts/helpers/handlebarhelper.js');

(function() {
  'use strict';

  if (svs.isServer) {
    registerHelpers(require('hbs'));
  } else {
    registerHelpers(Handlebars);
  }

  function registerHelpers(hbs) {
    hbs.registerHelper('math', function(lvalue, operator, rvalue) {
      lvalue = parseFloat(lvalue);
      rvalue = parseFloat(rvalue);

      return {
        '+': lvalue + rvalue,
        '-': lvalue - rvalue,
        '*': lvalue * rvalue,
        '/': lvalue / rvalue,
        '%': lvalue % rvalue
      }[operator];
    });

    hbs.registerHelper('concat', function() {
      var args = Array.prototype.slice.call(arguments);
      var concatString = '';

      for (var i = 0; i < args.length - 1; i++) {
        concatString += args[i];
      }

      return concatString;
    });
  }
})();


 })(window);